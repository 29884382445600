import _ from 'lodash';
import { AppError } from './axios';

export const cleanParams = <T extends Record<any, any>>(
  params: T
): Partial<T> => _.pickBy<Partial<T>>(params, (v) => v !== null && v !== '');

interface HandleAppFormErrorsOptions {
  parentErrorsMap?: Record<string, string>;
  setErrors?: (errors: any) => any;
  enqueueSnackbar: (message: string, options: any) => void;
}

export const handleAppFormErrors = (
  error: Error,
  {
    enqueueSnackbar,
    setErrors,
    parentErrorsMap = {}
  }: HandleAppFormErrorsOptions
) => {
  if (error instanceof AppError && Object.keys(error.errors).length) {
    if (setErrors) {
      const parsedErrors = _.chain(error.errors)
        .keys()
        .map((key) => {
          if (!key.includes('.')) {
            return null;
          }

          const [strippedKey] = key.split('.');
          const parentError = parentErrorsMap[strippedKey];
          if (!parentError) {
            return null;
          }

          return [strippedKey, parentError];
        })
        .filter()
        .fromPairs()
        .merge(error.errors)
        .value();

      setErrors(parsedErrors);
    }

    const { base } = error.errors;
    if (base) {
      enqueueSnackbar(base, { variant: 'error' });
    }

    return;
  }

  enqueueSnackbar(error.message || 'Error desconocido', { variant: 'error' });
};
