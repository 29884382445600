import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => (
  <img
    width="40%"
    height="40%"
    alt="Gases Logo"
    src="/static/logo.jpg"
    {...props}
  />
);

export default Logo;
