import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import { cleanParams } from 'src/utils/forms';

export interface Reminder {
  campaignId: string;
  name: string;
  createdBy: string;
  type: string;
  totalMessages: number;
  createdAt: string;
  category: string;

  hasBlacklisted: boolean;
  hasInvalid: boolean;
  hasInternational: boolean;

  canBeApproved: boolean;
  jobLogs: any[];
  priority?: number;

  [key: string]: any;
}

interface ReminderState {
  reminders: Reminder[] | null;
  total: number;

  detail: {
    loading: boolean;
    current: Reminder | null;
  },
  isCreateModalOpen: boolean;
}

const initialState: ReminderState = {
  reminders: null,
  total: 0,
  detail: {
    current: null,
    loading: true
  },
  isCreateModalOpen: false
};

const slice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {
    getReminders(state: ReminderState, action: PayloadAction<any>) {
      const { reminders, count } = action.payload;

      state.reminders = reminders;
      state.total = count;
    },
    remindersLoading(state: ReminderState) {
      state.reminders = null;
    },

    getReminder(state: ReminderState, action: PayloadAction<any>) {
      const { reminder, jobLogs } = action.payload;

      state.detail.loading = false;
      state.detail.current = {
        ...reminder,
        jobLogs,
      };
    },
    detailLoading(state: ReminderState) {
      state.detail.loading = true;
    },

    openCreateDialog(state: ReminderState) {
      state.isCreateModalOpen = true;
    },
    closeCreateDialog(state: ReminderState) {
      state.isCreateModalOpen = false;
    },
    createReminder(state: ReminderState) {
      state.isCreateModalOpen = false;
    },
  }
});

export const { reducer } = slice;

interface GetRemindersParams {
  status: string;
  query?: string
  page?: number;
  limit?: number;
}

export const getReminders = (params: GetRemindersParams): AppThunk => async (dispatch) => {
  dispatch(slice.actions.remindersLoading());

  const { status, query, ...rest } = params;
  const response = await axios.get('/admin/reminders', {
    params: {
      q: JSON.stringify({
        status,
        query
      }),
      ...rest
    }
  });

  dispatch(slice.actions.getReminders(response));
};

export const getReminder = (reminderId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.detailLoading());

  const response = await axios.get(`/admin/reminders/${reminderId}`);

  dispatch(slice.actions.getReminder(response));
};

export const cancelReminder = (reminderId: string): AppThunk => async (dispatch) => {
  await axios.post(`/admin/reminders/${reminderId}/cancel`);

  dispatch(getReminder(reminderId));
};

export const openCreateDialog = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openCreateDialog());
};

export const closeCreateDialog = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeCreateDialog());
};

interface CreateReminderParams {
  recipient: string;
  content: string;
  category: string;
  channels: string[];
  type: string;
  start: string;
  end: string | null;
  repeat: string | null;
  priority?: number;
  metadata: Record<string, any>;
}

export const createReminder = (params: CreateReminderParams): AppThunk => async (dispatch) => {
  const body = cleanParams(params);

  const response: any = await axios.post('/admin/reminders', {
    ...body,
  });

  dispatch(slice.actions.createReminder());
  dispatch(slice.actions.closeCreateDialog());
  return response.reminder;
};

export default slice;
