import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/app/campaigns',
        routes: [
          {
            exact: true,
            path: '/app/campaigns',
            component: lazy(() => import('src/views/campaigns/CampaignListView'))
          },
          {
            exact: true,
            path: '/app/campaigns/:campaignId',
            component: lazy(() => import('src/views/campaigns/CampaignDetailsView'))
          },
        ]
      },
      {
        path: '/app/reminders',
        routes: [
          {
            exact: true,
            path: '/app/reminders',
            component: lazy(() => import('src/views/reminders/ReminderListView'))
          },
          {
            exact: true,
            path: '/app/reminders/:reminderId',
            component: lazy(() => import('src/views/reminders/ReminderDetailsView'))
          },
        ]
      },
      {
        path: '/app/messages',
        routes: [
          {
            exact: true,
            path: '/app/messages',
            component: lazy(() => import('src/views/messages/MessageListView'))
          },
          {
            exact: true,
            path: '/app/messages/:messageId',
            component: lazy(() => import('src/views/messages/MessageDetailsView'))
          },
        ]
      },
      {
        path: '/app/urlshortener',
        routes: [
          {
            exact: true,
            path: '/app/urlshortener',
            component: lazy(() => import('src/views/urlshortener/UrlShortenerView'))
          }
        ]
      },
      {
        path: '/app/blacklists',
        routes: [
          {
            exact: true,
            path: '/app/blacklists',
            component: lazy(() => import('src/views/blacklists/BlacklistListView'))
          },
          {
            exact: true,
            path: '/app/blacklists/:recipient',
            component: lazy(() => import('src/views/blacklists/BlacklistDetailsView'))
          },
        ]
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/messages" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    component: () => <Redirect to="/app" />
  }
];

export default routes;
