import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import { ssoConfig } from 'src/config';
import axios from 'src/utils/axios';
import Keycloak from 'keycloak-js';

let keycloak: Keycloak.KeycloakInstance | null = null;

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends AuthState {
  method: 'SSO',
  loginWithPopup: (options?: unknown) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

let tokenRefreshTimeout = null;

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    if (tokenRefreshTimeout) {
      clearTimeout(tokenRefreshTimeout);
    }

    tokenRefreshTimeout = setTimeout(async () => {
      try {
        await keycloak.updateToken(60);
        setSession(keycloak.idToken);
      } catch (err) {
        console.log(err);
        setSession(null);
      }
    }, 30000);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'SSO',
  loginWithPopup: () => Promise.resolve(),
  logout: () => { }
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const loginWithPopup = async (options: unknown) => {
    await keycloak.login(options);

    const isAuthenticated = keycloak.authenticated;

    if (isAuthenticated) {
      const user = await keycloak.loadUserProfile();

      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.

      setSession(keycloak.idToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.email,
            email: user.email,
            name: user.firstName
          }
        }
      });
    } else {
      setSession(null);
    }
  };

  const logout = () => {
    keycloak.logout();

    dispatch({
      type: 'LOGOUT'
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        keycloak = Keycloak({
          url: 'https://auth.services.innovacion-gascaribe.com/auth',
          realm: 'innovacion',
          clientId: ssoConfig.client_id
        });

        const isAuthenticated = await keycloak.init({
          checkLoginIframe: false,
          onLoad: 'login-required'
        });

        if (isAuthenticated) {
          const user = await keycloak.loadUserProfile();

          setSession(keycloak.idToken);
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: {
                id: user.email,
                email: user.email,
                name: user.firstName
              }
            }
          });
        } else {
          setSession(null);
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        setSession(null);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'SSO',
        loginWithPopup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
