import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';

interface UrlShortenerState {
  shortenedUrl: string | null;
  loadingShortenedUrl: boolean
}

const initialState: UrlShortenerState = {
  shortenedUrl: null,
  loadingShortenedUrl: false
};

const slice = createSlice({
  name: 'urlshortener',
  initialState,
  reducers: {
    getShortenedUrl(state: UrlShortenerState, action: PayloadAction<{ shortenedUrl: string | null }>) {
      const { shortenedUrl } = action.payload;

      state.shortenedUrl = shortenedUrl;
    },
    loadingShortenedUrl(state: UrlShortenerState, action: PayloadAction<{ loading: boolean }>) {
      const { loading } = action.payload;
      state.loadingShortenedUrl = loading;
    },
    removeShortenedUrl(state: UrlShortenerState) {
      state.shortenedUrl = null;
    }
  }
});

export const { reducer } = slice;

export const getShortenedUrl = (url: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeShortenedUrl());
  dispatch(slice.actions.loadingShortenedUrl({ loading: true }));

  const { shortenedUrl }: any = await axios.post('/admin/url/shrink', {
    urlToShrink: url,
  });

  dispatch(slice.actions.getShortenedUrl({ shortenedUrl }));
  dispatch(slice.actions.loadingShortenedUrl({ loading: false }));
};

export const removeShortenedUrl = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeShortenedUrl());
};

export default slice;
