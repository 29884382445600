import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import { cleanParams } from 'src/utils/forms';
import { RecipientsCleanType } from 'src/types/recipientsCleanType';

export interface Campaign {
  campaignId: string;
  name: string;
  createdBy: string;
  type: string;
  totalMessages: number;
  priority?: number;
  createdAt: string;

  hasBlacklisted: boolean;
  hasInvalid: boolean;
  hasInternational: boolean;

  canBeApproved: boolean;
  jobLogs: any[];

  [key: string]: any;
}

const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
    if ((encoded.length % 4) > 0) {
      encoded += '='.repeat(4 - (encoded.length % 4));
    }
    resolve(encoded);
  };
  reader.onerror = (error) => reject(error);
});

interface CampaignsState {
  campaigns: Campaign[] | null;
  totalCampaigns: number;

  campaignDetail: {
    loading: boolean;
    currentCampaign: Campaign | null;
  },
  isCreateModalOpen: boolean;
}

const initialState: CampaignsState = {
  campaigns: null,
  totalCampaigns: 0,
  campaignDetail: {
    currentCampaign: null,
    loading: true
  },
  isCreateModalOpen: false
};

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    getCampaigns(state: CampaignsState, action: PayloadAction<any>) {
      const { campaigns, count } = action.payload;

      state.campaigns = campaigns;
      state.totalCampaigns = count;
    },
    campaignsLoading(state: CampaignsState) {
      state.campaigns = null;
    },

    getCampaign(state: CampaignsState, action: PayloadAction<any>) {
      const {
        campaign,
        blacklistedRecipients,
        internationalRecipients,
        invalidRecipients,
        spammedRecipients,
        externallyLockedRecipients,
        jobLogs
      } = action.payload;

      state.campaignDetail.loading = false;
      state.campaignDetail.currentCampaign = {
        ...campaign,
        blacklistedRecipients,
        internationalRecipients,
        invalidRecipients,
        spammedRecipients,
        externallyLockedRecipients,
        jobLogs,
        canBeApproved: campaign.status === 'created'
                        && blacklistedRecipients.count === 0
                        && invalidRecipients.count === 0
                        && spammedRecipients.count === 0
                        && externallyLockedRecipients.count === 0
      };
    },
    detailLoading(state: CampaignsState) {
      state.campaignDetail.loading = true;
    },

    openCreateDialog(state: CampaignsState) {
      state.isCreateModalOpen = true;
    },
    closeCreateDialog(state: CampaignsState) {
      state.isCreateModalOpen = false;
    },
    createCampaign(state: CampaignsState) {
      state.isCreateModalOpen = false;
    },
  }
});

export const { reducer } = slice;

interface GetCampaignsParams {
  status: string;
  query?: string
  page?: number;
  limit?: number;
}

export const getCampaigns = (params: GetCampaignsParams): AppThunk => async (dispatch) => {
  dispatch(slice.actions.campaignsLoading());

  const { status, query, ...rest } = params;
  const response = await axios.get('/admin/campaigns', {
    params: {
      q: JSON.stringify({
        status,
        query
      }),
      ...rest
    }
  });

  dispatch(slice.actions.getCampaigns(response));
};

export const getCampaign = (campaignId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.detailLoading());

  const response = await axios.get(`/admin/campaigns/${campaignId}`);

  dispatch(slice.actions.getCampaign(response));
};

export const approveCampaign = (campaignId: string): AppThunk => async (dispatch) => {
  await axios.post(`/admin/campaigns/${campaignId}/approve`);

  dispatch(getCampaign(campaignId));
};

export const cancelCampaign = (campaignId: string): AppThunk => async (dispatch) => {
  await axios.post(`/admin/campaigns/${campaignId}/cancel`);

  dispatch(getCampaign(campaignId));
};

export const openCreateDialog = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openCreateDialog());
};

export const closeCreateDialog = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeCreateDialog());
};

interface CreateCampaignParams {
  name: string;
  description: string;
  type: string;
  category: string;
  priority?: number;
  content?: string;
  file: File;
}

export const createCampaign = (params: CreateCampaignParams): AppThunk => async (dispatch) => {
  const body = cleanParams(params);

  const file = await toBase64(params.file);
  const response: any = await axios.post('/admin/campaigns', {
    ...body,
    file
  });

  dispatch(slice.actions.createCampaign());
  dispatch(slice.actions.closeCreateDialog());

  return response.campaign;
};

export const cleanRecipients = (campaignId: string, type: RecipientsCleanType) : AppThunk => async (dispatch) => {
  await axios.post(`/admin/campaigns/${campaignId}/clean/${type}`);

  dispatch(getCampaign(campaignId));
};

export default slice;
