import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import { cleanParams } from 'src/utils/forms';

export interface BlacklistRecord {
  id: string;
  recipient: string,
  reason: string;
  createdAt: string;
  status: string;
  createdBy: string;
  disabledBy?: string;
  disabledAt?: string;
  blockedChannel?: string;
}

export interface Blacklist {
  recipient: string;
  createdAt: string;
  active: boolean;
  nChannels: number;

  records?: BlacklistRecord[];
}

export const getBlacklistStatusDisplay = (active: boolean) => {
  if (active) {
    return {
      color: 'error' as const,
      label: 'Bloqueado'
    };
  }

  return {
    color: 'warning' as const,
    label: 'No bloqueado'
  };
};

interface BlacklistsState {
  blacklists: Blacklist[] | null;
  total: number;

  detail: {
    loading: boolean;
    current: Blacklist | null;
  },
  isCreateModalOpen: boolean;
}

const initialState: BlacklistsState = {
  blacklists: null,
  total: 0,
  detail: {
    current: null,
    loading: true
  },
  isCreateModalOpen: false
};

const slice = createSlice({
  name: 'blacklists',
  initialState,
  reducers: {
    getBlacklists(state: BlacklistsState, action: PayloadAction<any>) {
      const { blacklists, count } = action.payload;

      state.blacklists = blacklists;
      state.total = count;
    },
    messagesLoading(state: BlacklistsState) {
      state.blacklists = null;
    },

    getBlacklist(state: BlacklistsState, action: PayloadAction<any>) {
      const { blacklist } = action.payload;

      state.detail.loading = false;
      state.detail.current = {
        ...blacklist,
      };
    },
    detailLoading(state: BlacklistsState) {
      state.detail.loading = true;
    },

    openCreateDialog(state: BlacklistsState) {
      state.isCreateModalOpen = true;
    },
    closeCreateDialog(state: BlacklistsState) {
      state.isCreateModalOpen = false;
    },
    createBlacklist(state: BlacklistsState) {
      state.isCreateModalOpen = false;
    },
  }
});

export const { reducer } = slice;

interface GetBlacklistsParams {
  active: boolean;
  query?: string
  page?: number;
  limit?: number;
}

export const getBlacklists = (params: GetBlacklistsParams): AppThunk => async (dispatch) => {
  dispatch(slice.actions.messagesLoading());

  const { active, query, ...rest } = params;
  const q = {
    active,
    query
  };

  const response = await axios.get('/admin/blacklists', {
    params: {
      q: JSON.stringify(cleanParams(q)),
      ...rest
    }
  });

  dispatch(slice.actions.getBlacklists(response));
};

export const getBlacklist = (blacklistId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.detailLoading());

  const response = await axios.get(`/admin/blacklists/${blacklistId}`);

  dispatch(slice.actions.getBlacklist(response));
};

export const openCreateDialog = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openCreateDialog());
};

export const closeCreateDialog = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeCreateDialog());
};

interface CreateBlacklistParams {
  recipient: string;
}

export const createBlacklist = (params: CreateBlacklistParams): AppThunk => async (dispatch) => {
  const body = cleanParams(params);

  const response: any = await axios.post('/admin/blacklists', {
    ...body,
  });

  dispatch(slice.actions.createBlacklist());
  dispatch(slice.actions.closeCreateDialog());

  return response.blacklist;
};

export const disableBlacklist = (recipient: string, channels: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.detailLoading());

  await axios.post(`/admin/blacklists/${recipient}/disable`, { channels });

  dispatch(getBlacklist(recipient));
};

export default slice;
