import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import { cleanParams } from 'src/utils/forms';

export interface Message {
  messageId: string;
  createdBy: string;
  createdAt: string;
  status: string;
  content: string;
  recipient: string;
  category: string;
  priority?: number;
  campaignId?: string;
  campaign?: any;
  reminderId?: string;
  reminder?: any;
  channels: any[];
  jobLogs?: any[];
  metadata: any;
  tags: string[];
}

export const MessageStatusDisplayTable = {
  sent: {
    color: 'success',
    label: 'Enviado'
  },
  created: {
    color: 'warning',
    label: 'Pendiente'
  },
  failed: {
    color: 'error',
    label: 'Fallido'
  },
  'partial-failed': {
    color: 'warning',
    label: 'Parcialmente Fallido'
  },
  others: {
    color: 'warning',
    label: 'Desconocido'
  }
};

interface MessagesState {
  messages: Message[] | null;
  totalMessages: number;

  messageDetail: {
    loading: boolean;
    currentMessage: Message | null;
  },
  isCreateModalOpen: boolean;
}

const initialState: MessagesState = {
  messages: null,
  totalMessages: 0,
  messageDetail: {
    currentMessage: null,
    loading: true
  },
  isCreateModalOpen: false
};

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    getMessages(state: MessagesState, action: PayloadAction<any>) {
      const { messages, count } = action.payload;

      state.messages = messages;
      state.totalMessages = count;
    },
    messagesLoading(state: MessagesState) {
      state.messages = null;
    },

    getMessage(state: MessagesState, action: PayloadAction<any>) {
      const {
        message,
        jobLogs
      } = action.payload;

      state.messageDetail.loading = false;
      state.messageDetail.currentMessage = {
        ...message,
        jobLogs,
      };
    },
    detailLoading(state: MessagesState) {
      state.messageDetail.loading = true;
    },

    openCreateDialog(state: MessagesState) {
      state.isCreateModalOpen = true;
    },
    closeCreateDialog(state: MessagesState) {
      state.isCreateModalOpen = false;
    },
    createMessage(state: MessagesState) {
      state.isCreateModalOpen = false;
    },
  }
});

export const { reducer } = slice;

interface GetMessagesParams {
  status: string;
  query?: string
  page?: number;
  limit?: number;
}

export const getMessages = (params: GetMessagesParams): AppThunk => async (dispatch) => {
  dispatch(slice.actions.messagesLoading());

  const { status, query, ...rest } = params;
  const response = await axios.get('/admin/messages', {
    params: {
      q: JSON.stringify({
        status,
        query
      }),
      ...rest
    }
  });

  dispatch(slice.actions.getMessages(response));
};

export const getMessage = (messageId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.detailLoading());

  const response = await axios.get(`/admin/messages/${messageId}`);

  dispatch(slice.actions.getMessage(response));
};

export const openCreateDialog = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openCreateDialog());
};

export const closeCreateDialog = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeCreateDialog());
};

interface CreateMessageParams {
  recipient: string;
  content: string;
  channels: string[];
  category: string;
  priority?: number;
  templateName?: string;
  templateData?: string | {};
  metadata: Record<string, any>;
}

export const createMessage = (_params: CreateMessageParams): AppThunk => async (dispatch) => {
  const params = { ..._params };
  if (params.content) {
    params.content = params.content.trim();
  }

  const body = cleanParams(params);

  if (body.templateData) {
    body.templateData = { data: JSON.parse(body.templateData as string) };
  }

  const response: any = await axios.post('/admin/messages', {
    ...body,
  });

  dispatch(slice.actions.createMessage());
  dispatch(slice.actions.closeCreateDialog());

  return response.message;
};

export default slice;
