export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

const MAX_JOB_PRIORITY = 1_000_000;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

interface PrioritySet {
  default: number;
  max: number;
}

export enum PrioritySubjects {
  MESSAGE = 'message',
  CAMPAIGN = 'campaign',
  REMINDER = 'reminder',
}

export const PRIORITY_SETS: Record<PrioritySubjects, PrioritySet> = {
  message: {
    default: 10_000,
    max: MAX_JOB_PRIORITY,
  },
  reminder: {
    default: 1_000,
    max: 9_999,
  },
  campaign: {
    default: 0,
    max: 999,
  },
};
