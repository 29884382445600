import { combineReducers } from '@reduxjs/toolkit';
import { reducer as notificationsReducer } from 'src/slices/notification';
import { reducer as campaignsReducer } from 'src/slices/campaigns';
import { reducer as messagesReducer } from 'src/slices/messages';
import { reducer as blacklistsReducer } from 'src/slices/blacklists';
import { reducer as remindersReducer } from 'src/slices/reminders';
import { reducer as urlshortenerReducer } from 'src/slices/urlshortener';

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  campaigns: campaignsReducer,
  messages: messagesReducer,
  blacklists: blacklistsReducer,
  reminders: remindersReducer,
  urlshortener: urlshortenerReducer
});

export default rootReducer;
