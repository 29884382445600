import axios, { AxiosError } from 'axios';

export class AppError extends Error {
  readonly errors: Record<string, string>;

  constructor(baseError: AxiosError) {
    super(baseError.message);

    this.errors = baseError.response?.data?.errors || {};
  }
}

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response?.data?.data ?? response,
  (error: AxiosError) => {
    const appError = new AppError(error);
    const { base } = appError.errors;

    if (base === 'Unauthorized') {
      window.location.reload();
    }

    return Promise.reject(appError);
  }
);

export default axiosInstance;
